var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.requestsData.length && !_vm.fetchingRequests
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap align-center justify-space-between w-100",
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    _vm._l(_vm.availableRequestTypes, function (requestType) {
                      return _c(
                        "div",
                        { key: requestType },
                        [
                          _c("v-checkbox", {
                            staticClass: "mx-4 my-0",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                small: "",
                                                color:
                                                  _vm.getMailIconColor(
                                                    requestType
                                                  ),
                                              },
                                            },
                                            [_vm._v("mail")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "secondary--text caption text-uppercase",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .invitationAndRequestStatusTypes[
                                                    requestType
                                                  ]
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.requestsSwitchData[requestType],
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.requestsSwitchData,
                                  requestType,
                                  $$v
                                )
                              },
                              expression: "requestsSwitchData[requestType]",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("v-spacer"),
                  _vm.requestType === _vm.requestTypes.REQUESTS_TO_APPROVE
                    ? [
                        _c("v-text-field", {
                          staticClass: "ma-0 pa-0",
                          attrs: {
                            autofocus: "",
                            "append-icon": "search",
                            placeholder: "Filter",
                            dense: "",
                            "single-line": "",
                            outlined: "",
                          },
                          model: {
                            value: _vm.requestSearch,
                            callback: function ($$v) {
                              _vm.requestSearch = $$v
                            },
                            expression: "requestSearch",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("v-divider", { staticClass: "mb-5 mt-1" }),
              _c("v-data-iterator", {
                attrs: { items: _vm.filteredRequestsList },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return _vm._l(props.items, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin",
                                    },
                                    attrs: { inset: "" },
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "space-between",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.getMailIconColor(
                                                  _vm.getRequestStatus(item)
                                                ),
                                                "x-large": "",
                                              },
                                            },
                                            [_vm._v("mail")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm.requestType ===
                                                _vm.requestTypes
                                                  .REQUESTS_TO_APPROVE
                                                  ? _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            `${item.requestee_given_name} ${item.requestee_family_name}`
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    item.notification_timestamp
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "caption mt-1",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Sent on:"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.notification_timestamp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.getRequestStatus(
                                                      item
                                                    ) ===
                                                      _vm
                                                        .invitationAndRequestStatusTypes
                                                        .REVOKED &&
                                                    item.revocation_timestamp
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "caption mt-1 mb-0",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Revoked on:"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.revocation_timestamp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.getRequestStatus(
                                                      item
                                                    ) ===
                                                      _vm
                                                        .invitationAndRequestStatusTypes
                                                        .ACCEPTED &&
                                                    _vm.invitationOrRequestHasTimestamp(
                                                      item
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-1 caption",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Accepted on:"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dateTimeToHuman"
                                                                    )(
                                                                      item
                                                                        .request_responses[0]
                                                                        .timestamp
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mt-1",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Approved by:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                        .request_responses[0]
                                                                        .approver_full_name
                                                                    ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      item
                                                                        .request_responses[0]
                                                                        .approver_email
                                                                    ) +
                                                                    ") "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm.getRequestStatus(
                                                          item
                                                        ) ===
                                                          _vm
                                                            .invitationAndRequestStatusTypes
                                                            .REJECTED &&
                                                        _vm.invitationOrRequestHasTimestamp(
                                                          item
                                                        )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-1 caption",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Rejected on:"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dateTimeToHuman"
                                                                    )(
                                                                      item
                                                                        .request_responses[0]
                                                                        .timestamp
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mt-1",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Rejected by:"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                        .request_responses[0]
                                                                        .approver_full_name
                                                                    ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      item
                                                                        .request_responses[0]
                                                                        .approver_email
                                                                    ) +
                                                                    ") "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                _vm._l(
                                                  item.roles,
                                                  function (role, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              left: "",
                                                              "max-width":
                                                                "500",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "ComponentWithStyledBorder",
                                                                            [
                                                                              role.role_type ===
                                                                              _vm
                                                                                .invitationAndRequestRoleTypes
                                                                                .INSTANCE_ROLE
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex align-center",
                                                                                    },
                                                                                    [
                                                                                      role.Name ===
                                                                                      "N/A"
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1 ml-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "help_outline"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : role.Name ===
                                                                                          _vm
                                                                                            .roleTypes
                                                                                            .INSTANCE_VIEWER
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1 ml-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " visibility "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : role.Name ===
                                                                                          _vm
                                                                                            .roleTypes
                                                                                            .INSTANCE_EDITOR
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1 ml-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "edit"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      role.Instance
                                                                                        ? _c(
                                                                                            "span",
                                                                                            [
                                                                                              role.Space &&
                                                                                              role.Space !==
                                                                                                "N/A"
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          role.Space
                                                                                                        ) +
                                                                                                          "/"
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    role.Instance
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Target deleted"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : role.role_type ===
                                                                                  _vm
                                                                                    .invitationAndRequestRoleTypes
                                                                                    .SPACE_ROLE
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex align-center",
                                                                                    },
                                                                                    [
                                                                                      role.Name ===
                                                                                      "N/A"
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1 ml-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "help_outline"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1 ml-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "security"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            role.Space
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : role.role_type ===
                                                                                  _vm
                                                                                    .invitationAndRequestRoleTypes
                                                                                    .ORG_ROLE
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex align-center",
                                                                                    },
                                                                                    [
                                                                                      role.Name ===
                                                                                      "N/A"
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1 ml-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "help_outline"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1 ml-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "apartment"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            role.Organization
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-column pa-2",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Role:"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  role.role_type ===
                                                                  _vm
                                                                    .invitationAndRequestRoleTypes
                                                                    .INSTANCE_ROLE
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          role.Name !==
                                                                          "N/A"
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " Instance "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-lowercase",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          role.Name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      role.Name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  role.role_type ===
                                                                  _vm
                                                                    .invitationAndRequestRoleTypes
                                                                    .SPACE_ROLE
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Space administrator"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  role.role_type ===
                                                                  _vm
                                                                    .invitationAndRequestRoleTypes
                                                                    .ORG_ROLE
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " Organization "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-lowercase",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  role.Name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                                role.role_type ===
                                                                _vm
                                                                  .invitationAndRequestRoleTypes
                                                                  .INSTANCE_ROLE
                                                                  ? _c("div", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Space:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            role.Space
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                role.role_type ===
                                                                _vm
                                                                  .invitationAndRequestRoleTypes
                                                                  .INSTANCE_ROLE
                                                                  ? _c("div", [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Instance:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            role.Instance
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-bold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Note:"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  role.Name ===
                                                                  "N/A"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "The target object has been deleted"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : role.method ===
                                                                      _vm
                                                                        .roleUpdateOptions
                                                                        .OVERWRITE_CURRENT_ROLE
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " This role will overwrite the current user role, even if the current role is more powerful that this one. "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : role.method ===
                                                                      _vm
                                                                        .roleUpdateOptions
                                                                        .OVERWRITE_CURRENT_ROLE_IF_LOWER
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " This role will overwrite the current role only if the current role is less powerful than this one. "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }, [
                                        _vm.getRequestStatus(item) ===
                                          _vm.invitationAndRequestStatusTypes
                                            .PENDING ||
                                        _vm.getRequestStatus(item) ===
                                          _vm.invitationAndRequestStatusTypes
                                            .CREATED
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _vm.requestType ===
                                                _vm.requestTypes.OWN_REQUESTS
                                                  ? _c(
                                                      "InvitationActionsDialog",
                                                      {
                                                        attrs: {
                                                          successFetchAction:
                                                            () => {
                                                              _vm.$store.dispatch(
                                                                "userStore/fetchUserOwnRequests"
                                                              )
                                                            },
                                                          invitationInfo: item,
                                                          actionType:
                                                            _vm.actionTypes
                                                              .REVOKE_REQUEST,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm.requestType ===
                                                _vm.requestTypes
                                                  .REQUESTS_TO_APPROVE
                                                  ? _c(
                                                      "InvitationActionsDialog",
                                                      {
                                                        attrs: {
                                                          successFetchAction:
                                                            () => {
                                                              _vm.$store.dispatch(
                                                                "userStore/fetchUserToApproveRequests"
                                                              )
                                                            },
                                                          invitationInfo: item,
                                                          actionType:
                                                            _vm.actionTypes
                                                              .REJECT_REQUEST,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm.requestType ===
                                                _vm.requestTypes
                                                  .REQUESTS_TO_APPROVE
                                                  ? _c(
                                                      "InvitationActionsDialog",
                                                      {
                                                        attrs: {
                                                          successFetchAction:
                                                            () => {
                                                              _vm.$store.dispatch(
                                                                "userStore/fetchUserToApproveRequests"
                                                              )
                                                            },
                                                          invitationInfo: item,
                                                          actionType:
                                                            _vm.actionTypes
                                                              .APPROVE_REQUEST,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                    {
                      key: "no-results",
                      fn: function () {
                        return [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                value: true,
                                color: "error",
                                icon: "warning",
                                text: "",
                              },
                            },
                            [_vm._v("Your search for found no results.")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2068483577
                ),
              }),
            ],
            1
          )
        : !_vm.fetchingRequests &&
          !_vm.requestsData.length &&
          _vm.requestType === _vm.requestTypes.OWN_REQUESTS
        ? _c("v-alert", { attrs: { type: "info", prominent: "", text: "" } }, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("No submitted requests"),
              ]),
              _c("span", [_vm._v("You haven't submitted any request so far.")]),
            ]),
          ])
        : !_vm.fetchingRequests &&
          !_vm.requestsData.length &&
          _vm.requestType === _vm.requestTypes.REQUESTS_TO_APPROVE
        ? _c("v-alert", { attrs: { type: "info", prominent: "", text: "" } }, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("No requests to approve"),
              ]),
              _c("span", [
                _vm._v(
                  "You haven't received any request from other users so far."
                ),
              ]),
            ]),
          ])
        : _vm.fetchingRequests
        ? _c(
            "div",
            _vm._l(
              [
                { id: "c1", opacity: 1 },
                { id: "c2", opacity: 0.75 },
                { id: "c3", opacity: 0.5 },
              ],
              function (item) {
                return _c("div", { key: item.id, staticClass: "my-6" }, [
                  _c(
                    "div",
                    { style: { opacity: item.opacity } },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item-avatar-two-line" },
                      }),
                    ],
                    1
                  ),
                ])
              }
            ),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }